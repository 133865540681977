import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Subject } from "rxjs";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';

import { ApiService } from "../api.service";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit  {
  @Input() endpoint: string;
  @Input() sort: any;
  @Input() index: number;
  @Input() size: number;
  @Output() result = new EventEmitter<any>();

  private term = new Subject<string>();

  constructor(
    private readonly api: ApiService,
  ) {}

  ngOnInit() {
    this.term.debounceTime(500)
      .switchMap(term => this.searchTerm(term))
      .subscribe(result => this.result.emit(result));
    this.term.next('');
  }

  refresh() {
    this.term.next('');
  }

  private searchTerm(term: string) {
    return this.api.post(this.endpoint, {
      search: term,
      sort: this.sort,
      page: {
        currentPage: this.index,
        noofRecords: this.size,
      },
    }).map(res => res.json().meta_data);
  }
}

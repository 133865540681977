import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/api.service';
import { IDriver } from '../../driver/driver';
import { Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { MatStepper } from '@angular/material';
import * as _moment from 'moment';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { ActivatedRoute } from '@angular/router';
import { DriverImage } from '../../driver/driver.enum';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'DD/mm/YYYY',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};
@Component({
  selector: 'app-create-driver',
  templateUrl: './create-driver.component.html',
  styleUrls: ['./create-driver.component.css'], providers: [
    // `MomentDateTimeAdapter` can be automatically provided by importing
    // `OwlMomentDateTimeModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },

    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ]
})
export class CreateDriverComponent implements OnInit {

  /**
  * Notifier service
  */
  private notifier: NotifierService;
  isLinear = false;
  drivers: IDriver[];
  data = {} as IDriver;
  profileFormGroup: FormGroup;
  submittedprofileForm = false;
  contactFormGroup: FormGroup;
  submittedcontactForm = false;
  licenceFormGroup: FormGroup;
  submitted = false;
  driverProfilePic: any;
  driverLicenseImage: any;
  policeCheckImage: any;
  drivingHistoryImage: any;
  identityImage: any;
  url: string;
  adminInfo: any = {};
  queryParams: string;
  registering = false;
  registered = false;

  // tslint:disable-next-line:max-line-length
  constructor(private _formBuilder: FormBuilder, private _route: ActivatedRoute, private api: ApiService, public router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }
  ngOnInit() {
    this.queryParams = this._route.snapshot.queryParams['id'];
    // this.getData();
    this.getDriverUniqueId();
    this.profileFormGroup = this._formBuilder.group({
      driverId: new FormControl(''),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      doB: new FormControl('', Validators.required),
      referringDriver: new FormControl(''),
      familyName: new FormControl(''),
      middleName: new FormControl(''),
      gender: new FormControl('', Validators.required),
      nickName: new FormControl(''),
      customId: new FormControl(''),
    });
    this.contactFormGroup = this._formBuilder.group({
      address: new FormGroup({
        addressNo: new FormControl(''),
        addressStreet: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        postcode: new FormControl('')
      }),
      emergency: new FormGroup({
        name: new FormControl(''),
        mobileNumber: new FormControl(''),
        email: new FormControl('')
      }),
      mobileNumber: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
    });
    this.licenceFormGroup = this._formBuilder.group({
      bank: new FormGroup({
        name: new FormControl(''),
        accountNo: new FormControl('', Validators.required),
        routingNo: new FormControl(''),
        nameOnAccount: new FormControl(''),
        payPalId: new FormControl('')
      }),
      license: new FormGroup({
        licenseNo: new FormControl('', Validators.required),
        licenseType: new FormControl(''),
        expiryDate: new FormControl(''),
      }),
      insurance: new FormGroup({
        contact: new FormControl(''),
        name: new FormControl(''),
        id: new FormControl(''),
      }),
      incidentReport: new FormGroup({
        date: new FormControl(''),
        comment: new FormControl(''),
      }),
      title: new FormControl(''),
      key: new FormControl('')
    });
  }
  // convenience getter for easy access to form fields
  get profileInfo() { return this.profileFormGroup.controls; }
  // convenience getter for easy access to form fields
  get contactInfo() { return this.contactFormGroup.controls; }
  // convenience getter for easy access to form fields
  get licenseInfo() { return this.licenceFormGroup.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.profileFormGroup.invalid || this.contactFormGroup.invalid || this.licenceFormGroup.invalid) {
      return;
    } else {
      const _Data = {
        'driverId': this.profileFormGroup.value.driverId,
        'customId': this.profileFormGroup.value.customId,
        'firstName': this.profileFormGroup.value.firstName,
        'middleName': this.profileFormGroup.value.middleName,
        'lastName': this.profileFormGroup.value.lastName,
        'gender': this.profileFormGroup.value.gender,
        'doB': this.profileFormGroup.value.doB,
        'license': this.licenceFormGroup.value.license,
        'mobileNumber': this.contactFormGroup.value.mobileNumber,
        'email': this.contactFormGroup.value.email,
        'emergency': this.contactFormGroup.value.emergency,
        'referringDriver': '',
        'bank': this.licenceFormGroup.value.bank,
        'address': this.contactFormGroup.value.address,
        'insurance': this.licenceFormGroup.value.insurance,
        'incidentReport': { 'date': '2020.05.27', 'comment': this.licenceFormGroup.value.incidentReport.comment },
        'consentInformation': this.licenceFormGroup.value.consentInformation,
        'imeiDevice': {
          'title': this.licenceFormGroup.value.title,
          'key': this.licenceFormGroup.value.key,
        },
        'owner': this.queryParams
      };

      this.registering = true;
      this.api.post('driverportal/register', _Data)
        .subscribe((res) => {
          this.registering = false;
          const result = res.json();
          if (result && result.meta_data) {
            if (
              this.driverProfilePic ||
              this.driverLicenseImage ||
              this.policeCheckImage ||
              this.drivingHistoryImage ||
              this.identityImage) {
              this.uploadProfilePic(
                result.meta_data.driver._id,
                this.driverProfilePic,
                this.driverLicenseImage,
                this.policeCheckImage,
                this.drivingHistoryImage,
                this.identityImage,
                );
            } else {
              this.registered = true;
            }
          }
        },
        error => {
          this.registering = false;
          const err = error.json();
          this.notifier.notify('error', err.message_detail);
        }
        );
    }
  }
  getData() {
    this.api.get('driver/list').subscribe((res) => {
      const result = res.json();
      if (result.meta_data) {
        this.drivers = result.meta_data.drivers;
      } else {
        this.drivers = null;
      }
    });
  }
  getDriverUniqueId() {
    this.api.get('driverportal/uniqueid').subscribe((res) => {
      const result = res.json();
      if (result.meta_data) {
        this.data.driverId = result.meta_data.newId;
      } else {
        this.drivers = null;
      }
    });
  }
  onSelectProfilePic(ProfilePicevent) {
    if (ProfilePicevent.target.files && ProfilePicevent.target.files[0]) {
      this.driverProfilePic = ProfilePicevent.target.files[0];
      const reader = new FileReader();
      this.driverProfilePic = reader.readAsDataURL(ProfilePicevent.target.files[0]);
      this.driverProfilePic = ProfilePicevent.target.files[0];
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (ProfilePicevent) => {
        this.url = event.target['result'];
      };
    }
  }
  onSelectLicenseImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.driverLicenseImage = event.target.files[0];
    }
  }
  uploadProfilePic(DriverId, ProfileImage, License, PoliceCheck, DrivingHistory, Identity) {
    const formdata = new FormData();
    formdata.append(DriverImage.Profile, ProfileImage);
    formdata.append(DriverImage.License, License);
    formdata.append(DriverImage.PoliceCheck, PoliceCheck);
    formdata.append(DriverImage.DrivingHistory, DrivingHistory);
    formdata.append(DriverImage.Identity, Identity);
    formdata.append('id', DriverId);
    const Data = formdata;
    this.api.post(`driver/images/add`, Data)
      .subscribe((res) => {
        const result = res.json();
        this.notifier.notify('success', 'Driver Added Successfully.');
        this.router.navigate(['driver']);
      },
      error => console.log(error)
      );
  }
  validateProfileInfo(stepper: MatStepper) {
    this.submittedprofileForm = true;
    if (this.profileFormGroup.invalid) {
      return false;
    } else {
      stepper.next();
    }
  }
  validateContactInfo(stepper: MatStepper) {
    this.submittedcontactForm = true;
    if (this.contactFormGroup.invalid) {
      return false;
    } else {
      stepper.next();
    }
  }
}

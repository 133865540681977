import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { SliderItems } from './slider.content';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  /**
	 * Notifier service
	 */
  private notifier: NotifierService;

  data: any = {};
  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  index = 0;
  infinite = true;
  direction = 'right';
  directionToggle = true;
  autoplay = true;

  /**
	 * Constructor
   * @param {ApiService} api  Api service
   * @param {Router} router   Router
	 * @param {AuthService} auth Auth service
   * @param {SliderItems} sliderItems Slider Items
	 * @param {NotifierService} notifier Notifier service
	 */

  constructor(private api: ApiService, public router: Router, private auth: AuthService, public sliderItems: SliderItems,
    notifier: NotifierService) {
      this.notifier = notifier;
     }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/meter']);
    }
  }
  login() {
    this.api.post('account/login', this.loginForm.value)
      .subscribe((res) => {
        const result = res.json();
        if (result && result.meta_data.user) {
          this.auth.setUser(result.meta_data.user);
          this.auth.setToken(result.meta_data.user.token);
          // localStorage.setItem('token', result.meta_data.user.token);
          this.getUserRole();
        } else {
          this.notifier.notify('error', result.message);
        }
      });
  }


  getUserRole() {
    this.api.get('meteradmin/adminrole').subscribe((res) => {
      const result = res.json();
      if (result.meta_data.adminRole) {
        this.auth.setUserRole(result.meta_data.adminRole);
        if (result.meta_data.adminRole === 'Meter Admin') {
          this.router.navigate(['meter']);
        } else if(this.auth.getUserRole() === 'Driver') {
          this.router.navigate(['analytics']);
        } else {
          this.router.navigate(['account']);
        }
      }
    });
  }

}

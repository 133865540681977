import 'hammerjs';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { DemoMaterialModule } from '../demo-material-module';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ExtraRoutes } from './extra.routing';
import { CreateDriverComponent } from './create-driver/create-driver.component';
@NgModule({
    imports: [
        CommonModule,
        DemoMaterialModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        CdkTableModule,
        RouterModule.forChild(ExtraRoutes),
    ],
    providers: [

    ],
    entryComponents: [
    ],
    declarations: [CreateDriverComponent],
})

export class ExtraModule { }

import { NgModule } from '@angular/core';
import { DemoMaterialModule } from '../demo-material-module';
import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { SharedRoutes } from './shared.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthService } from './auth/auth.service';
import { AuthGuard, MeterAdminGuard } from './auth/auth.guard';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { SliderItems } from './login/slider.content';
import { PaginationConfig } from './pagination/pagination-config';
import { SearchComponent } from './search/search.component';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { DriverPostComponent } from './driver-post-shared/post.component';
import { DriverPostProfileComponent } from './driver-post-shared/profile/profile.component';
import { DriverPostContactComponent } from './driver-post-shared/contact/contact.component';
import { DriverPostBankComponent } from './driver-post-shared/bank/bank.component';
import { DriverPostLicenseComponent } from './driver-post-shared/license/license.component';
import { DriverPostIncidentComponent } from './driver-post-shared/incident/incident.component';
import { DriverPostImeiComponent } from './driver-post-shared/imei/imei.component';
import { DriverPostConsentComponent } from './driver-post-shared/consent/consent.component';
import { DriverPostRegistrationComponent } from './driver-post-shared/registration/registration.component';


@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    LoginComponent,
    SearchComponent,
    DriverPostComponent,
    DriverPostImeiComponent,
    DriverPostProfileComponent,
    DriverPostContactComponent,
    DriverPostBankComponent,
    DriverPostLicenseComponent,
    DriverPostRegistrationComponent,
    DriverPostIncidentComponent,
    DriverPostConsentComponent,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SearchComponent,
    DriverPostComponent,
    DriverPostImeiComponent,
    DriverPostProfileComponent,
    DriverPostContactComponent,
    DriverPostBankComponent,
    DriverPostLicenseComponent,
    DriverPostRegistrationComponent,
    DriverPostIncidentComponent,
    DriverPostConsentComponent,
  ],
  imports: [
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxHmCarouselModule,
    CommonModule,
    RouterModule.forChild(SharedRoutes),
    NgSelectModule
  ],
  providers: [MenuItems, AuthGuard, MeterAdminGuard, AuthService, SliderItems, PaginationConfig]
})
export class SharedModule { }

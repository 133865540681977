import { Component, Input } from "@angular/core";

import { IDriver } from "../../../driver/driver.interface";

@Component({
  selector: 'app-driver-post-bank',
  templateUrl: './bank.component.html',
})
export class DriverPostBankComponent {
  @Input() driver: IDriver;
}

import { Component, Input } from "@angular/core";

import { IDriver } from "../../../driver/driver.interface";
import { DriverImage } from "../../../driver/driver.enum";

@Component({
  selector: 'app-driver-post-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css'],
})
export class DriverPostLicenseComponent {
  @Input() driver: IDriver;
  @Input() multipartImages : FormData;

  DriverImage = DriverImage;

  processFile(imageInput: any, name: string) {
    let files = imageInput.files;
    for(let x = 0; x < files.length; x++) {
      this.multipartImages.append(name, files[x], files[x].name);
    }
  }
}

import { Component, Input } from "@angular/core";

import { IDriver } from "../../../driver/driver.interface";

@Component({
  selector: 'app-driver-post-imei',
  templateUrl: './imei.component.html',
})
export class DriverPostImeiComponent {
  @Input() driver: IDriver;
}

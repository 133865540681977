import { Component, Input, OnInit, ViewChild, ElementRef } from "@angular/core";

import { IFleet } from "../../../fleet/fleet";
import { IDriverType } from "../../../driver/driver-type/driver-type";
import { IDriver } from "../../../driver/driver.interface";
import { MapsAPILoader } from "@agm/core";

@Component({
  selector: 'app-driver-post-profile',
  templateUrl: './profile.component.html',
})
export class DriverPostProfileComponent implements OnInit {

  @ViewChild('Address') addressRef: ElementRef;

  @Input() driver: IDriver;
  @Input() fleets: IFleet[];
  @Input() driverTypes: IDriverType[];

  private addressAutocomplete: google.maps.places.Autocomplete;

  constructor(
    private readonly mapsAPILoader: MapsAPILoader,
  ) {}

  async ngOnInit() {
    await this.mapsAPILoader.load();
    this.initAutocomplete();
  }

  async initAutocomplete() {
    this.addressAutocomplete = new google.maps.places.Autocomplete(this.addressRef.nativeElement);

    this.addressAutocomplete.addListener('place_changed', () => {
      const place: google.maps.places.PlaceResult = this.addressAutocomplete.getPlace();
      this.driver.address = place.formatted_address;
    });
  }

  updateDriverId() {
    const last4MobileDigits = this.driver.mobileNumber ? this.driver.mobileNumber.substr(-4) : '';
    this.driver.driverId = [this.driver.firstName, this.driver.lastName, last4MobileDigits]
      .filter(part => !!part)
      .map(part => part.toUpperCase())
      .join('-');
  }
}

export enum DriverGender {
  Male = 'male',
  Female = 'female',
}

export enum DriverStatus {
  Active = 'active',
  Inactive = 'deactive',
}

export enum DriverImage {
  Profile = 'profile',
  License = 'license',
  PoliceCheck = 'policeCheck',
  DrivingHistory = 'drivingHistory',
  Identity = 'identity',
}

import { Component, Input } from "@angular/core";

import { IDriver } from "../../../driver/driver.interface";
import { DriverImage } from '../../../driver/driver.enum';

@Component({
  selector: 'app-driver-post-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class DriverPostRegistrationComponent {
  @Input() driver: IDriver;
  @Input() multipartImages : FormData;

  DriverImage = DriverImage;

  processFile(imageInput: any, name: string) {
    let files = imageInput.files;
    for(let x = 0; x < files.length; x++) {
      this.multipartImages.append(name, files[x], files[x].name);
    }
  }
}

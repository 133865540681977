import { Directive } from '@angular/core';

@Directive({
  selector: '[appReverseGeocode]'
})
export class ReverseGeocodeDirective  {

  constructor() { }

}

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      return true;
    }else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}

@Injectable()
export class MeterAdminGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.getUserRole() === 'Meter Admin') {
      return true;
    }
    if (this.authService.getUserRole() === 'Driver') {
      this.router.navigateByUrl('/manage');
      return false;
    }
    return false;
  }
}

import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AuthGuard, MeterAdminGuard } from './shared/auth/auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: FullComponent,

    children: [
      {
        path: '',
        canActivate: [AuthGuard, MeterAdminGuard],
        redirectTo: '/meter',
        pathMatch: 'full'
      },
      {
        path: 'login',
        loadChildren: './shared/shared.module#SharedModule'
      },
      {
        canActivate: [AuthGuard],
        path: 'driver',
        loadChildren: './driver/driver.module#DriverModule'
      },
      {
        canActivate: [AuthGuard, MeterAdminGuard],
        path: 'driver-type',
        loadChildren: './driver/driver-type/driver-type.module#DriverTypeModule'
      },
      {
        path: 'vehicle',
        canActivate: [AuthGuard],
        loadChildren: './vehicle/vehicle.module#VehicleModule'
      },
      {
        path: 'vehicle-type',
        canActivate: [AuthGuard, MeterAdminGuard],
        loadChildren: './vehicle/vehicle-type/vehicle-type.module#VehicleTypeModule'
      },
      {
        path: 'fleet',
        canActivate: [AuthGuard, MeterAdminGuard],
        loadChildren: './fleet/fleet.module#FleetModule'
      },
      // {
      //   path: 'dispatch',
      //   canActivate: [AuthGuard, MeterAdminGuard],
      //   loadChildren: './dispatch/dispatch.module#DispatchModule'
      // },
      {
        path: 'operator',
        canActivate: [AuthGuard, MeterAdminGuard],
        loadChildren: './operator/operator.module#OperatorModule'
      },
      {
        path: 'passenger',
        canActivate: [AuthGuard, MeterAdminGuard],
        loadChildren: './passenger/passenger.module#PassengerModule'
      },
      {
        path: 'ride-live',
        canActivate: [AuthGuard],
        loadChildren: './ride-live/ride-live.module#RideLiveModule'
      },
      {
        path: 'ride-history',
        canActivate: [AuthGuard],
        loadChildren: './ride-history/ride-history.module#RideHistoryModule'
      },
      {
        path: 'analytics',
        canActivate: [AuthGuard],
        loadChildren: './analytics/analytics.module#AnalyticsModule'
      },
      {
        path: 'meter',
        canActivate: [AuthGuard, MeterAdminGuard],
        loadChildren: './meter/meter.module#MeterModule'
      },
      {
        path: 'account',
        canActivate: [AuthGuard],
        loadChildren: './account/account.module#AccountModule'
      },
      {
        path: 'device',
        canActivate: [AuthGuard, MeterAdminGuard],
        loadChildren: './device/device.module#DeviceModule'
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: './profile/profile.module#ProfileModule'
      },
      {
        path: 'manage',
        canActivate: [AuthGuard],
        loadChildren: './manage/manage.module#ManageModule'
      },
      {
        path: 'planner',
        canActivate: [AuthGuard],
        loadChildren: './planner/planner.module#PlannerModule'
      },
    ]
  },
  {
    path: 'createdriver',
    loadChildren: './extra/extra.module#ExtraModule'
  },
  {
    path: 'createDriver',
    loadChildren: './driver/driverPublic/driverPublic.module#DriverPublic'
  },
  {
    path: 'createVehicle',
    loadChildren: './vehicle/vehicle-public/vehicle-public.module#VehiclePublicModule'
  },
  {
    path: 'audit_log.html',
    loadChildren: './ctep/ctep.module#CtepModule'
  },
];

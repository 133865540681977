import { Component, Input } from "@angular/core";
import { IDriver, IDriverSettings } from "../../../driver/driver.interface";

@Component({
  selector: 'app-driver-post-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.css'],
})
export class DriverPostConsentComponent {
  @Input() settings: IDriverSettings;
  @Input() driver: IDriver;
}

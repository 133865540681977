import { Component, Input } from "@angular/core";

import { IDriver } from "../../../driver/driver.interface";

@Component({
  selector: 'app-driver-post-contact',
  templateUrl: './contact.component.html',
})
export class DriverPostContactComponent {
  @Input() driver: IDriver;
}

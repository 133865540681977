import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, forkJoin, of } from "rxjs";
import "rxjs/add/observable/of";

import { ApiService } from "../api.service";
import { NotifierService } from "angular-notifier";
import { IDriver, IDriverSettings } from "../../driver/driver.interface";
import { IFleet } from "../../fleet/fleet";
import { IDriverType } from "../../driver/driver-type/driver-type";
import { AuthService } from "../auth/auth.service";
import { DriverImage } from "../../driver/driver.enum";

@Component({
  selector: 'driver-post-component-shared',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css'],
})
export class DriverPostComponent implements OnInit {

  @ViewChild('ProfilePic') profilePicUpload: ElementRef;
  @Input() driverId: string;
  @Input() manageUrl: boolean;
  @Input() publicUrl: boolean;

  public driver: IDriver;
  public fleets: IFleet[];
  public driverTypes: IDriverType[];
  public id: string;
  public profilePicUrl: string;
  public activeLink: string = 'imei';
  public loading = false;
  public profileImage: Blob;
  public identityImageUrls: string[];
  public multipartImages: FormData = new FormData();
  public isPublicURL: Boolean = false;
  public userId: String = '';
  public settings: IDriverSettings = { consent: null };

  nav = [
    { name: 'IMEI', link: 'imei' },
    { name: 'Profile', link: 'profile' },
    { name: 'Emergency Contact', link: 'contact' },
    { name: 'Bank Info', link: 'bank' },
    { name: 'License', link: 'license' },
    { name: 'Registration', link: 'registration' },
    { name: 'Incidents', link: 'incident' },
  ];

  constructor(
    private readonly api: ApiService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly notifier: NotifierService,
    private readonly auth: AuthService
  ) { }

  ngOnInit() {
    this.driver = <IDriver>{
      _id: (this.route.snapshot.queryParams['id']) ? this.route.snapshot.queryParams['id'] : this.driverId,
      bank: {},
      incidentReport: {},
      license: {}
    };
    this.makeComponentPublic();
    this.getData();
  }

  makeComponentPublic() {
    this.isPublicURL = this.publicUrl;
    let userDetails = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    this.userId = (userDetails && userDetails.ID && !this.isPublicURL ) ? (userDetails && userDetails.ID) : this.route.snapshot.queryParams['userId'];
  }

  checkUrl() {
    return (this.router.url.indexOf('public') !== -1);
  }

  getData() {
    this.loading = true;
    const listParams = { search: '', sort: {}, page: { currentPage: 1, noofRecords: 100000 } };
    const driverRequest = this.driver._id ? this.api.get(`driver/d/${this.driver._id}?${this.manageUrl ? 'driver=true' : ''}`)
      : Observable.of({ json() { return { meta_data: { driver: {} } } } });

    forkJoin(driverRequest).subscribe((responses) => {
      const [driverResponse] = responses.map(res => res.json().meta_data);
      const owner = this.manageUrl ? driverResponse.driver.owner : this.userId;
      
      if (this.driver._id) {
        this.driver = <IDriver>Object.assign(this.driver, driverResponse.driver);
        this.refreshImages();
      }
      
      this.api.get(`driver/settings/get?owner=${owner}`).subscribe((res) => {
        const { settings } = res.json().meta_data;
        this.settings = settings;
        if (this.settings.consent !== null ) {
          this.nav.push({ name: 'Consent', link: 'consent' });
        }

        if (!this.manageUrl && ['pending-review', 'approved'].includes(this.driver.approvalStatus)) {
          this.driver.consent = true;
        }
      });
      
      this.getFleets(listParams, owner);
      this.getDriverTypes(listParams, owner);
      this.loading = false;
    });
  }

  getFleets(listParams, owner) {
    const fleetsRequest = this.isPublicURL ?
      this.api.post('fleet/list/public', Object.assign(listParams, { userId: owner })) :
      this.api.post('fleet/list', Object.assign(listParams, { userId: owner }));
    fleetsRequest.subscribe(res => {
      let fleetsResponse = res.json().meta_data;
      this.fleets = <IFleet[]>fleetsResponse.fleets;
    })
  }

  getDriverTypes(listParams, owner) {
    const driverTypesRequest = this.isPublicURL ?
      this.api.post('driver-type/list/public', Object.assign(listParams, { userId: owner })) :
      this.api.post('driver-type/list', Object.assign(listParams, { userId: owner }));
    driverTypesRequest.subscribe(res => {
      let driverTypesResponse = res.json().meta_data;
      this.driverTypes = <IDriverType[]>driverTypesResponse.driverTypes;
    })
  }

  save() {
    if (!this.driver.firstName || !this.driver.lastName) {
      this.notifier.notify('error', 'Driver\'s full name required');
      return;
    }
    if (!this.driver.email) {
      this.notifier.notify('error', 'Driver\'s email address required');
      return;
    }
    if (!this.driver.mobileNumber) {
      this.notifier.notify('error', 'Driver\'s phone number required');
      return;
    }
    if (this.settings.consent !== null && this.driver.consent !== true) {
      this.notifier.notify('error', 'You need to consent to the terms prior to submitting the application');
      return;
    }
    this.loading = true;
    let driverSaveRequest: Observable<any>;
    let isNew = true;
    if (this.driver._id) {
      isNew = false;
      driverSaveRequest = this.api.put(`driver/d/${this.driver._id}?${this.manageUrl ? 'driver=true' : ''}`, this.driver);
    } else {
      if (!this.profileImage) {
        this.notifier.notify('error', 'You need to upload a photo');
        this.loading = false;
        return;
      }
      if (!this.isPublicURL) {
        this.driver.isAdmin = true;
      }
      driverSaveRequest = this.api.post('driver/add', Object.assign({}, this.driver, { userId: this.userId }));
    }

    driverSaveRequest.subscribe((res) => {
      const response = res.json();
      this.notifier.notify('success', `Driver ${isNew ? 'added' : 'saved'} successfully`);
      this.driver = <IDriver>response.meta_data.driver;
      if (this.manageUrl) {
        this.router.navigate(['manage']);
        this.loading = false;
      } else {
        if (!this.isPublicURL) {
          if (isNew) {
            this.upload();
            this.router.navigate(['driver/post'], { queryParams: { id: this.driver._id } })
          } else {
            this.upload();
            this.router.navigate(['driver']);
          }
        } else {
          this.upload().subscribe(() => {
            this.auth.logout();
            this.router.navigate(['login']);
          });
          return;
        }
      }
      //this.uploadImage(this.driver._id);
      this.loading = false;
    }, (err) => {
      this.notifier.notify('error', `Driver ${isNew ? 'add' : 'save'} failed`);
      this.loading = false;
    });
  }

  uploadImage(id) {
    this.multipartImages.append('id', id);
    this.multipartImages.append(DriverImage.Profile, this.profileImage);
    this.api.multiPartRequest(`driver/images/add`, this.multipartImages).subscribe(responses => {
      this.multipartImages = new FormData();
    });
  }

  upload(): Observable<any> {
    if (!this.profileImage || !this.driver._id) {
      return of({});
    }

    this.loading = true;

    const data = new FormData();
    data.append(DriverImage.Profile, this.profileImage);
    data.append('id', this.driver._id);
    const request = this.api.post('driver/images/add', data);
    request.subscribe((res) => {
      const { images } = res.json().meta_data;
      this.driver.images = images || [];
      this.refreshImages();
      this.notifier.notify('success', 'Driver profile picture uploaded successfully');
      this.loading = false;
    });
    return request;
  }

  refreshImages() {
    const profileImages = this._getImageUrls(DriverImage.Profile);
    if (profileImages && profileImages.length > 0) {
      this.driver.profileImageUrl = profileImages[0];
    }

    this.driver.licenseImageUrls = this._getImageUrls(DriverImage.License);
    this.driver.policeCheckImageUrls = this._getImageUrls(DriverImage.PoliceCheck);
    this.driver.drivingHistoryImageUrls = this._getImageUrls(DriverImage.DrivingHistory);
    this.driver.identityImageUrls = this._getImageUrls(DriverImage.Identity);
  }

  _getImageUrls(kind: DriverImage): string[] {
    return this.driver.images
      .filter(image => image.kind === kind)
      .map(image => image.url);
  }

  selectProfilePic() {
    this.profilePicUpload.nativeElement.click();
  }

  onSelectProfilePic(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.profileImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event: any) => {
        this.driver.images = [{ kind: DriverImage.Profile, url: _event.target.result }];
      };

      this.upload();
    }
  }

  getTitle() {
    if (this.manageUrl) {
      return `Edit Details`
    }
    return this.driver._id ? 'Edit Driver' : 'Add Driver';
  }

}

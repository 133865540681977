import { ChangeDetectorRef, Component, NgZone, OnDestroy, ViewChild, HostListener, Directive, AfterViewInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems, Menu } from '../../../shared/menu-items/menu-items';
import { HostBinding } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { AuthService } from '../../../shared/auth/auth.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class AppSidebarComponent implements OnDestroy, OnInit  {
  mobileQuery: MediaQueryList;
  expanded: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  private _mobileQueryListener: () => void;
  @Input() item: Menu;
  @Input() depth: number;
  menuList: Menu[];
  constructor(private auth: AuthService, private api: ApiService, public router: Router,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public menuItems: MenuItems) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    if (this.auth.getUserRole() === 'Meter Admin') {
      this.menuList = this.menuItems.getMenuitem();
    } else if(this.auth.getUserRole() === 'Driver') {
      this.menuList = this.menuItems.getMenuitemForDriver();
    } else {
      this.menuList = this.menuItems.getMenuitemForAdmin();
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getLink(state) {
    return ['/', ...state.split('/')]
  }
}
